import FinancialsTable from "../CompanyTabs/FinancialTabs/FinancialsTable";
import FairValueInfoTabs from "./FairValueInfoTabs";
import {useEffect, useState} from "react";
import {useQuery} from "@tanstack/react-query";
import {useSelector} from "react-redux";
import axios from "axios";
import Config from "../../Config";

const cagr_values = async (code, exchange) => {
    try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-overview/${code}/${exchange}/annual`, Config());
        return response.data;
    } catch (error) {
        throw error;
    }
}


function FairValueInfoTable({groupedData}) {

    return (
        <>
            {groupedData &&
                <FinancialsTable
                    highlightHeadings={["Cash & Equivalents", "Total Debt", "ROIC", "P/E", "P/FCF", "P/S", "Debt/Equity", "Debt/Assets"]}
                    groupedData={groupedData}
                    tableClassName={'company-overview-fair-value-table'}
                    thClassName={"company-overview-financial-table-field-names-fair-value"}/>
            }

        </>
    );
}

export default FairValueInfoTable;
