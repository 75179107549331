import {useEffect, useState} from "react";
import FinancialsControls from "./FinancialsControls";
import FinancialsTable from "./FinancialsTable";
import {numberWithCommas, return_strong_num, return_value_in_million} from "../../Utils";
import axios from "axios";
import Config from "../../../Config";

const IncomeStatementTab = ({code, exchange}) => {
    const [loading, setLoading] = useState(true)

    const [groupedData, setGroupedData] = useState(null)

    const [active, setActive] = useState('annual')

    const [meta, setMeta] = useState({curr_symbol: "", currency: ""})

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financial-meta/${code}/${exchange}/`, Config())
            .then(response => {
                setMeta(response.data)
            })
    }, [code, exchange])

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/company-overview-financials-income-statement/${code}/${exchange}/${active}`)
            .then(response => {
                group_income(response.data, active)
                setLoading(false)
            })
            .catch(error => console.log(error.message))
    }, [active, code, exchange])

    const group_income = (_data, type) => {
        if (active === 'annual') {
            let data = {
                dates: [], content: {
                    revenue: {
                        name: 'Revenue & Gross Profit', data: {
                            'Revenue': [],
                            '% Growth Yearly': [],
                            'Cost of Revenue': [],
                            '% Growth Yearly ': [],

                            'Gross Profit': [],
                            '% Growth Yearly  ': [],
                            '% Gross Margin  ': [],
                        }
                    },
                    operating_activities: {
                        name: 'Operating Activities', data: {
                            'Research & Development': [],
                            'Selling General & Admin': [],
                            'Operating Expense': [],
                            '% Growth Yearly': [],

                            'Operating Income': [],
                            '% Growth Yearly ': [],
                            '% Operating Margin': [],
                        }
                    },
                    interest_income_expenses: {
                        name: "Interest Income/Expenses",
                        data: {
                            'Interest Income': [],
                            'Interest Expense': [],
                        }
                    },
                    net_income_ebit_ebitda: {
                        name: 'Net Income, EBIT & EBITDA', data: {
                            'Other Income Expenses': [],
                            'Income Tax Expenses': [],
                            'Net Income': [],
                            '% Growth Yearly': [],
                            '% Net Margin': [],

                            'EBIT': [],
                            '% Growth Yearly ': [],
                            '% EBIT Margin': [],
                            'Depreciation': [],

                            'EBITDA': [],
                            '% Growth Yearly  ': [],
                            '% EBITDA Margin': []
                        }
                    },
                    supplemental_items: {
                        name: 'Supplemental Items', data: {
                            'Effective Tax Rate %': [],
                            'Revenue per Share': [],
                            'GAAP EPS': [],
                            'Non-GAAP EPS': [],
                        }
                    }
                }
            }
            _data.map((item) => {
                data['dates'].push(item.date)

                // Revenue
                data['content']['revenue']['data'][`Revenue`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalRevenue']))))
                data['content']['revenue']['data'][`% Growth Yearly`].push(return_strong_num(item['growth']['historical_data']['totalRevenue'] * 100))
                data['content']['revenue']['data']['Cost of Revenue'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['costOfRevenue']))))
                data['content']['revenue']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['costOfRevenue'] * 100))

                data['content']['revenue']['data'][`Gross Profit`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['grossProfit']))))
                data['content']['revenue']['data']['% Growth Yearly  '].push(return_strong_num(item['growth']['historical_data']['grossProfit'] * 100))
                data['content']['revenue']['data']['% Gross Margin  '].push(return_strong_num(item['additional']['grossMargin'] * 100))

                // Operating Activities
                data['content']['operating_activities']['data'][`Research & Development`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['researchDevelopment']))))
                data['content']['operating_activities']['data'][`Selling General & Admin`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['sellingGeneralAdministrative']))))
                data['content']['operating_activities']['data'][`Operating Expense`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalOperatingExpenses']))))
                data['content']['operating_activities']['data'][`% Growth Yearly`].push(return_strong_num(item['growth']['historical_data']['totalOperatingExpenses'] * 100))

                data['content']['operating_activities']['data']['Operating Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['operatingIncome']))))
                data['content']['operating_activities']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['operatingIncome'] * 100))
                data['content']['operating_activities']['data']['% Operating Margin'].push(return_strong_num(item['additional']['operatingMargin'] * 100))

                // interest_income_expenses
                data['content']['interest_income_expenses']['data']['Interest Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['interestIncome']))))
                data['content']['interest_income_expenses']['data']['Interest Expense'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['interestExpense']))))

                // net_income_ebit_ebitda
                data['content']['net_income_ebit_ebitda']['data']['Other Income Expenses'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalOtherIncomeExpenseNet']))))
                data['content']['net_income_ebit_ebitda']['data'][`Income Tax Expenses`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['incomeTaxExpense']))))
                data['content']['net_income_ebit_ebitda']['data']['Net Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['netIncome']))))
                data['content']['net_income_ebit_ebitda']['data'][`% Growth Yearly`].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))
                data['content']['net_income_ebit_ebitda']['data'][`% Net Margin`].push(return_strong_num(item['additional']['netMargin'] * 100))

                data['content']['net_income_ebit_ebitda']['data']['EBIT'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['ebit']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth Yearly '].push(return_strong_num(item['growth']['historical_data']['ebit'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBIT Margin'].push(return_strong_num(item['additional']['ebitMargin'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['Depreciation'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['depreciation']))))

                data['content']['net_income_ebit_ebitda']['data']['EBITDA'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['ebitda']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth Yearly  '].push(return_strong_num(item['growth']['historical_data']['ebitda'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBITDA Margin'].push(return_strong_num(item['additional']['ebitdaMargin'] * 100))

                // supplemental_items
                data['content']['supplemental_items']['data']['Effective Tax Rate %'].push(return_strong_num(item['additional']['taxRate'] * 100))
                data['content']['supplemental_items']['data']['Revenue per Share'].push(return_strong_num(item['income_statement']['revenuePerShare']))
                data['content']['supplemental_items']['data']['GAAP EPS'].push(return_strong_num(item['income_statement']['earningsPerShare']))
                data['content']['supplemental_items']['data']['Non-GAAP EPS'].push(return_strong_num(item['earnings']['epsActual']))

            })
            setGroupedData(data)
        } else if (active === 'quarter') {
            let data = {
                dates: [],
                content: {
                    revenue: {
                        name: 'Revenue & Gross Profit',
                        data: {
                            'Revenue': [],
                            '% Growth Quarterly': [],
                            '% Growth YoY': [],
                            'Cost of Revenue': [],
                            '% Growth Quarterly ': [],
                            '% Growth YoY ': [],

                            'Gross Profit': [],
                            '% Growth Quarterly  ': [],
                            '% Growth YoY  ': [],
                            '% Gross Margin': []
                        }
                    },
                    operating_activities: {
                        name: 'Operating Activities', data: {
                            'Research & Development': [],
                            'Selling General & Admin': [],
                            'Operating Expense': [],
                            '% Growth Quarterly': [],
                            '% Growth YoY': [],

                            'Operating Income': [],
                            '% Growth Quarterly ': [],
                            '% Growth YoY ': [],
                            '% Operating Margin': [],
                        }
                    },
                    interest_income_expenses: {
                        name: "Interest Income/Expenses",
                        data: {
                            'Interest Income': [],
                            'Interest Expense': [],
                        }
                    },
                    // operating_income: {
                    //     name: 'Operating Income',
                    //     data: {
                    //
                    //         'Interest Expense': [],
                    //         'Other Income Expenses': []
                    //     }
                    // },

                    net_income_ebit_ebitda: {
                        name: 'Net Income, EBIT & EBITDA',
                        data: {
                            'Other Income Expenses': [],
                            'Income Tax Expenses': [],
                            'Net Income': [],
                            '% Growth Quarterly': [],
                            '% Growth YoY': [],
                            '% Net Margin': [],

                            'EBIT': [],
                            '% Growth Quarterly ': [],
                            '% Growth YoY ': [],
                            '% EBIT Margin': [],
                            'Depreciation': [],
                            'EBITDA': [],
                            '% Growth Quarterly  ': [],
                            '% Growth YoY  ': [],
                            '% EBITDA Margin': [],
                        }
                    }, supplemental_items: {
                        name: 'Supplemental Items', data: {
                            'Effective Tax Rate %': [],
                            'Revenue per Share': [],
                            'GAAP EPS': [],
                            'Non-GAAP EPS': [],
                            // 'Dividend Per Share': [],
                            // 'Payout Ratio': [],
                            // 'Retention Ration': [],
                        }
                    }
                }
            }
            _data.map((item) => {
                data['dates'].push(item.date)

                // Revenue
                data['content']['revenue']['data'][`Revenue`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalRevenue']))))
                data['content']['revenue']['data'][`% Growth Quarterly`].push(return_strong_num(item['growth']['historical_data']['totalRevenue'] * 100))
                data['content']['revenue']['data'][`% Growth YoY`].push(return_strong_num(item['yoy']['historical_data']['totalRevenue'] * 100))
                data['content']['revenue']['data']['Cost of Revenue'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['costOfRevenue']))))
                data['content']['revenue']['data'][`% Growth Quarterly `].push(return_strong_num(item['growth']['historical_data']['costOfRevenue'] * 100))
                data['content']['revenue']['data'][`% Growth YoY `].push(return_strong_num(item['yoy']['historical_data']['costOfRevenue'] * 100))

                data['content']['revenue']['data'][`Gross Profit`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['grossProfit']))))
                data['content']['revenue']['data'][`% Growth Quarterly  `].push(return_strong_num(item['growth']['historical_data']['grossProfit'] * 100))
                data['content']['revenue']['data'][`% Growth YoY  `].push(return_strong_num(item['yoy']['historical_data']['grossProfit'] * 100))
                data['content']['revenue']['data'][`% Gross Margin`].push(return_strong_num(item['additional']['grossMargin'] * 100))

                // Operating Expense
                data['content']['operating_activities']['data'][`Research & Development`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['researchDevelopment']))))
                data['content']['operating_activities']['data'][`Selling General & Admin`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['sellingGeneralAdministrative']))))
                data['content']['operating_activities']['data'][`Operating Expense`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalOperatingExpenses']))))
                data['content']['operating_activities']['data'][`% Growth Quarterly`].push(return_strong_num(item['growth']['historical_data']['totalOperatingExpenses'] * 100))
                data['content']['operating_activities']['data'][`% Growth YoY`].push(return_strong_num(item['yoy']['historical_data']['totalOperatingExpenses'] * 100))

                data['content']['operating_activities']['data'][`Operating Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['operatingIncome']))))
                data['content']['operating_activities']['data'][`% Growth Quarterly `].push(return_strong_num(item['growth']['historical_data']['operatingIncome'] * 100))
                data['content']['operating_activities']['data'][`% Growth YoY `].push(return_strong_num(item['yoy']['historical_data']['operatingIncome'] * 100))
                data['content']['operating_activities']['data'][`% Operating Margin`].push(return_strong_num(item['additional']['operatingMargin'] * 100))

                // interest_income_expenses
                data['content']['interest_income_expenses']['data']['Interest Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['interestIncome']))))
                data['content']['interest_income_expenses']['data']['Interest Expense'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['interestExpense']))))

                // Net Income
                data['content']['net_income_ebit_ebitda']['data']['Other Income Expenses'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['totalOtherIncomeExpenseNet']))))
                data['content']['net_income_ebit_ebitda']['data'][`Income Tax Expenses`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['incomeTaxExpense']))))
                data['content']['net_income_ebit_ebitda']['data'][`Net Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['netIncome']))))
                data['content']['net_income_ebit_ebitda']['data'][`% Growth Quarterly`].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))
                data['content']['net_income_ebit_ebitda']['data'][`% Growth YoY`].push(return_strong_num(item['yoy']['historical_data']['netIncome_income_statement'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% Net Margin'].push(return_strong_num(item['additional']['netMargin'] * 100))

                data['content']['net_income_ebit_ebitda']['data']['EBIT'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['ebit']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth Quarterly '].push(return_strong_num(item['growth']['historical_data']['ebit'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% Growth YoY '].push(return_strong_num(item['yoy']['historical_data']['ebit'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBIT Margin'].push(return_strong_num(item['additional']['ebitMargin'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['Depreciation'].push(numberWithCommas(return_value_in_million(return_strong_num(item['cash_flow']['depreciation']))))

                data['content']['net_income_ebit_ebitda']['data']['EBITDA'].push(numberWithCommas(return_value_in_million(return_strong_num(item['income_statement']['ebitda']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth Quarterly  '].push(return_strong_num(item['growth']['historical_data']['ebitda'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% Growth YoY  '].push(return_strong_num(item['yoy']['historical_data']['ebitda'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBITDA Margin'].push(return_strong_num(item['additional']['ebitdaMargin'] * 100))

                // supplemental_items
                data['content']['supplemental_items']['data']['Effective Tax Rate %'].push(return_strong_num(item['additional']['taxRate'] * 100))
                data['content']['supplemental_items']['data']['Revenue per Share'].push(return_strong_num(item['income_statement']['revenuePerShare']))
                data['content']['supplemental_items']['data']['GAAP EPS'].push(return_strong_num(item['income_statement']['earningsPerShare']))
                data['content']['supplemental_items']['data']['Non-GAAP EPS'].push(return_strong_num(item['earnings']['epsActual']))

            })
            setGroupedData(data)
        } else if (active === 'ttm') {
            let data = {
                dates: [], content: {
                    revenue: {
                        name: 'Revenue',
                        data: {
                            'Revenue': [],
                            '% Growth TTM': [],
                            'Cost of Revenue': [],
                            '% Growth TTM ': [],

                            'Gross Profit': [],
                            '% Growth TTM  ': [],
                            '% Gross Margin': []
                        }
                    }, operating_activities: {
                        name: 'Operating Activities',
                        data: {
                            'Research & Development': [],
                            'Selling General & Admin': [],
                            'Operating Expense': [],
                            '% Growth TTM': [],
                            'Operating Income': [],
                            '% Growth TTM ': [],
                            '% Operating Margin': [],
                        }
                    }, interest_income_expenses: {
                        name: "Interest Income/Expenses",
                        data: {
                            'Interest Income': [],
                            'Interest Expense': [],
                        }
                    }, net_income_ebit_ebitda: {
                        name: 'Net Income, EBIT & EBITDA',
                        data: {
                            'Other Income Expenses': [],
                            'Income Tax Expenses': [],
                            'Net Income': [],
                            '% Growth TTM': [],
                            '% Net Margin': [],

                            'EBIT': [],
                            '% Growth TTM ': [],
                            '% EBIT Margin': [],
                            'Depreciation': [],

                            'EBITDA': [],
                            '% Growth TTM  ': [],
                            '% EBITDA Margin': []
                        }
                    }, supplemental_items: {
                        name: 'Supplemental Items', data: {
                            'Effective Tax Rate %': [],
                            'Revenue per Share': [],
                            'GAAP EPS': [],
                            'Non-GAAP EPS': [],
                        }
                    }
                }
            }
            _data.map((item) => {
                data['dates'].push(item.date)

                // Revenue
                data['content']['revenue']['data'][`Revenue`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalRevenue']))))
                data['content']['revenue']['data'][`% Growth TTM`].push(return_strong_num(item['growth']['historical_data']['totalRevenue'] * 100))
                data['content']['revenue']['data']['Cost of Revenue'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['costOfRevenue']))))
                data['content']['revenue']['data']['% Growth TTM '].push(return_strong_num(item['growth']['historical_data']['costOfRevenue'] * 100))

                data['content']['revenue']['data']['Gross Profit'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['grossProfit']))))
                data['content']['revenue']['data'][`% Growth TTM  `].push(return_strong_num(item['growth']['historical_data']['grossProfit'] * 100))
                data['content']['revenue']['data']['% Gross Margin'].push(return_strong_num(item['ttm']['historical_data']['grossMargin'] * 100))

                // Operating Activities
                data['content']['operating_activities']['data'][`Research & Development`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['researchDevelopment']))))
                data['content']['operating_activities']['data'][`Selling General & Admin`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['sellingGeneralAdministrative']))))
                data['content']['operating_activities']['data'][`Operating Expense`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalOperatingExpenses']))))
                data['content']['operating_activities']['data'][`% Growth TTM`].push(return_strong_num(item['growth']['historical_data']['totalOperatingExpenses'] * 100))

                data['content']['operating_activities']['data'][`Operating Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['operatingIncome']))))
                data['content']['operating_activities']['data']['% Growth TTM '].push(return_strong_num(item['growth']['historical_data']['operatingIncome'] * 100))
                data['content']['operating_activities']['data']['% Operating Margin'].push(return_strong_num(item['ttm']['historical_data']['operatingMargin'] * 100))

                // interest_income_expenses
                data['content']['interest_income_expenses']['data']['Interest Income'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['interestIncome']))))
                data['content']['interest_income_expenses']['data']['Interest Expense'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['interestExpense']))))

                // Net Income
                data['content']['net_income_ebit_ebitda']['data']['Other Income Expenses'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['totalOtherIncomeExpenseNet']))))
                data['content']['net_income_ebit_ebitda']['data'][`Income Tax Expenses`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['incomeTaxExpense']))))
                data['content']['net_income_ebit_ebitda']['data'][`Net Income`].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['netIncome_income_statement']))))
                data['content']['net_income_ebit_ebitda']['data'][`% Growth TTM`].push(return_strong_num(item['growth']['historical_data']['netIncome_income_statement'] * 100))
                data['content']['net_income_ebit_ebitda']['data'][`% Net Margin`].push(return_strong_num(item['ttm']['historical_data']['netMargin'] * 100))

                data['content']['net_income_ebit_ebitda']['data']['EBIT'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['ebit']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth TTM '].push(return_strong_num(item['growth']['historical_data']['ebit'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBIT Margin'].push(return_strong_num(item['ttm']['historical_data']['ebitMargin'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['Depreciation'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['depreciation']))))

                data['content']['net_income_ebit_ebitda']['data']['EBITDA'].push(numberWithCommas(return_value_in_million(return_strong_num(item['ttm']['historical_data']['ebitda']))))
                data['content']['net_income_ebit_ebitda']['data']['% Growth TTM  '].push(return_strong_num(item['growth']['historical_data']['ebitda'] * 100))
                data['content']['net_income_ebit_ebitda']['data']['% EBITDA Margin'].push(return_strong_num(item['ttm']['historical_data']['ebitdaMargin'] * 100))

                // supplemental_items
                data['content']['supplemental_items']['data']['Effective Tax Rate %'].push(return_strong_num(item['ttm']['historical_data']['taxRate'] * 100))
                data['content']['supplemental_items']['data']['Revenue per Share'].push(return_strong_num(item['ttm']['historical_data']['revenuePerShare']))
                data['content']['supplemental_items']['data']['GAAP EPS'].push(return_strong_num(item['ttm']['historical_data']['earningsPerShare']))
                data['content']['supplemental_items']['data']['Non-GAAP EPS'].push(return_strong_num(item['ttm']['historical_data']['epsActual']))

            })
            setGroupedData(data)
        }
    }

    const handleActive = (type) => {
        setActive(type)
        setLoading(true)
    }

    return (
        <>
            <FinancialsControls
                options={[{label: "Annual", key: "annual"}, {label: "Quarterly", key: 'quarter'}, {label: "TTM", key: 'ttm'}]}
                handleActive={handleActive} active={active} meta={meta}/>
            {
                loading ?
                    <div style={{width: '100%', height: '100%', display: ' flex'}}>
                        <div className="spinner-grow text-warning" role="status"
                             style={{margin: "auto", width: "5rem", height: "5rem"}}>
                        </div>
                    </div>
                    :
                    groupedData &&
                    <FinancialsTable groupedData={groupedData}
                                     highlightHeadings={["Revenue", "Gross Profit", "Operating Income", "EBIT", "EBITDA"]}/>
            }
        </>
    );
}

export default IncomeStatementTab;
