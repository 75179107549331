import React, {useEffect, useState} from 'react';
import {getModalStyle} from "../Utils";
import axios from "axios";
import Config from "../../Config";
import {CgClose} from "react-icons/cg";
import {FaTrash} from "react-icons/fa";
import {toast} from "react-hot-toast";

function FairValueModal({code, exchange, handleFairValueModal, setEditableFields, setYearsData, setLoadedSettings}) {

    const [modalStyle] = useState(getModalStyle);

    const [fairValueSettings, setFairValueSettings] = useState([])

    const [settingID, setSettingID] = useState(null)

    const handleChange = (e) => {
        const id = e.target.value
        setSettingID(id)

        setFairValueSettings((prev) => {
            return prev.map((item) => {
                if (item.id == id) {
                    item.checked = true
                } else {
                    item.checked = false
                }
                return item
            })
        })
    }

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-settings/${code}/${exchange}/`, Config())
            .then(response => {
                let data = response.data
                data.map((item) => {
                    if (item.current) {
                        setSettingID(item.id)
                        item.checked = true
                    } else {
                        item.checked = false
                    }
                })

                setFairValueSettings(data)
            }).catch(err => {
            console.log(err.response.data)
        })
    }, []);

    const load_settings = () => {
        console.log(settingID)

        axios.get(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-setting/${code}/${exchange}/${settingID}/`, Config())
            .then(response => {
                const settings = response.data.settings

                setLoadedSettings(response.data.created_at)

                setEditableFields(settings.editableFields)
                setYearsData(settings.yearsData)

                handleFairValueModal()

                toast.success(`Settings loaded from ${response.data.created_at}`)
            }).catch(err => {
            console.log(err.response.data)
        })
    }

    const delete_settings = (ID) => {
        axios.delete(`${process.env.REACT_APP_API_URL}/finqube-api/fair-value-setting/${code}/${exchange}/${ID}/`, Config())
            .then(response => {
                toast.success("Setting Deleted")

                setFairValueSettings(prev => prev.filter(item => item.id !== ID))

            }).catch(err => {
            toast.success("Failed to delete, please try again")
            console.log(err.response.data)
        })
    }

    return (
        <div style={modalStyle}
             className={'best-iSqore-filter-modal-body fair-value-modal-body'}>

            <div style={{display: 'flex', marginBottom: 10}}>
                <p className={'search-modal-text-heading'}>Previous Saved Settings</p>
                <CgClose className={'close-modal'} onClick={handleFairValueModal}/>
            </div>

            {fairValueSettings.map((fairValueSetting, index) => {
                return (
                    <div className="d-flex" style={{margin: "10px 0"}}>
                        <input className="form-check-input filter-exchange-checkbox"
                               type="radio"
                               onChange={handleChange}
                               style={{margin: "auto 10px auto 0"}}
                               value={fairValueSetting.id}
                               id={`fair-value-${fairValueSetting.created_at}`}
                               checked={fairValueSetting.checked}/>

                        <label for={`fair-value-${fairValueSetting.created_at}`}
                               style={{fontSize: 12, fontWeight: 400, margin: "auto 0"}}>
                            {fairValueSetting.created_at} {fairValueSetting.current && "(Current)"} {` @ Price: ${fairValueSetting.price}`}
                        </label>
                        {!fairValueSetting.checked &&
                            <FaTrash
                                onClick={() => {delete_settings(fairValueSetting.id)}}
                                style={{margin: "auto 0 auto auto", color: '#d84f68', cursor: "pointer"}}/>
                        }
                    </div>
                )
            })}

            <div className={'d-flex'}>
                <button
                    onClick={load_settings}
                    className={'btn btn-warning'}
                    style={{fontWeight: 600, fontSize: 12, marginTop: 10, marginLeft: "auto"}}>
                    Load Settings
                </button>
            </div>

        </div>
    );
}

export default FairValueModal;